import type {
  BroadcastFn,
  SchemaInstructionsHelper,
} from '@backstage-components/base';
import {assign, createMachine} from 'xstate';
import {ComponentDefinition} from './OpenLoginDefinition';

export type InstructionSchema = SchemaInstructionsHelper<
  typeof ComponentDefinition
>;

export const OpenLoginMachine = createMachine<
  Context,
  InstructionSchema,
  Typestate
>(
  {
    id: 'OpenLogin',
    initial: 'idle',
    predictableActionArguments: true,
    states: {
      idle: {
        on: {'OpenLogin:verify': {target: 'pending'}},
      },
      pending: {
        on: {
          'OpenLogin:success': {
            target: 'success',
            actions: ['updateAttendee'],
          },
          'OpenLogin:failure': {
            target: 'failure',
            actions: ['updateReason'],
          },
        },
      },
      success: {
        entry: ['broadcastSuccess'],
        on: {'OpenLogin:verify': {target: 'pending'}},
      },
      failure: {
        entry: ['broadcastFailure', 'clearAttendeeId'],
        on: {'OpenLogin:verify': {target: 'pending'}},
      },
    },
  },
  {
    actions: {
      broadcastFailure: (context, event) => {
        if (event.type === 'OpenLogin:failure') {
          context.broadcast({
            type: 'OpenLogin:on-failure',
            meta: {
              showId: context.showId,
              reason: event.meta.reason,
            },
          });
        }
      },
      broadcastSuccess: (context, event) => {
        if (event.type === 'OpenLogin:success') {
          if (typeof window === 'object') {
            const e: OpenLoginSuccessEvent = new CustomEvent(
              OpenLoginSuccessEventName,
              {detail: {attendee: event.meta.attendee, showId: context.showId}}
            );
            document.body.dispatchEvent(e);
          }
        }
      },
      clearAttendeeId: assign((context, event) => {
        if (event.type === 'OpenLogin:failure') {
          // Return `BaseContext`
          return {
            attendee: undefined,
            broadcast: context.broadcast,
            showId: context.showId,
          };
        } else {
          return context;
        }
      }),
      updateAttendee: assign((context, event) => {
        if (event.type === 'OpenLogin:success') {
          const attendee = event.meta.attendee;
          const nextContext: SuccessContext = {
            broadcast: context.broadcast,
            showId: context.showId,
            attendee,
          };
          return nextContext;
        } else {
          return context;
        }
      }),
      updateReason: assign((context, event) => {
        if (event.type === 'OpenLogin:failure') {
          return {
            broadcast: context.broadcast,
            showId: context.showId,
            reason: event.meta.reason,
          };
        } else {
          return context;
        }
      }),
    },
  }
);

interface BaseContext {
  broadcast: BroadcastFn<(typeof ComponentDefinition)['instructions']>;
  showId: string;
  attendee?: Attendee;
}

interface FailureContext extends BaseContext {
  reason?: string;
}

interface SuccessContext extends BaseContext {
  attendee: Attendee;
}

type Context = SuccessContext | BaseContext;

type Typestate =
  | {value: 'idle'; context: BaseContext}
  | {value: 'pending'; context: BaseContext}
  | {value: 'success'; context: SuccessContext}
  | {value: 'failure'; context: FailureContext};

interface Attendee {
  id: string;
  email: string | null;
  name: string;
  chatTokens: ChatToken[];
  tags: string[];
}

interface ChatToken {
  token: string;
}

export const OpenLoginSuccessEventName = 'OpenLogin:success';

export type OpenLoginSuccessEvent = CustomEvent<
  Pick<SuccessContext, 'attendee' | 'showId'>
>;
